<template>
  <div class="wenjuan">
    <div class="wenjuan1">
      <div class="wenjuan2">您在二院就医过程中，觉得那些地方需要加强？</div>
      <div class="wenjuan3">
        <van-checkbox
          checked-color="#00D48A"
          icon-size="16px"
          v-model="isWeakness"
          >医疗技术</van-checkbox
        >
        <van-checkbox checked-color="#00D48A" icon-size="16px" v-model="isCough"
          >硬件设施</van-checkbox
        >
        <van-checkbox
          checked-color="#00D48A"
          icon-size="16px"
          v-model="isRhinobyon"
          >收费价格</van-checkbox
        >
        <van-checkbox
          checked-color="#00D48A"
          icon-size="16px"
          v-model="isSoreThroat"
          >医疗服务</van-checkbox
        >
        <!-- <template #icon="props">
              <i :class="props.checked ? 'activeIcon' : 'inactiveIcon'"></i>
              </template> -->
      </div>
    </div>
    <van-button @click="aa1">投票</van-button>
  </div>
</template>
<script>
import { Toast } from "vant";

export default {
  data() {
    return {
      isWeakness: "",
      isCough: "",
      isRhinobyon: "",
      isSoreThroat: "",
    };
  },
  methods: {
    aa1() {
      Toast.fail({
        message: "投票成功",
        duration: 500,
        onClose: () => {
          this.$router.push({
            name: "personalCenter",
          });
        },
      });
    },
  },
};
</script>
<style scoped>
.body {
  background-color: #f8f9f9;
}

.wenjuan {
  width: 100%;
  height: 100%;
}

.wenjuan1 {
  width: 9rem;
  height: 200px;
  border-radius: 10px;
  margin: auto;
  background-color: #fff;
  margin-top: 20px;
  font-size: 14px;
}
.wenjuan2 {
  height: 40px;
  line-height: 40px;
  text-align: left;
  padding-left: 20px;
}
.wenjuan3 {
  height: 160px;
  padding-left: 20px;
}
.van-checkbox {
  margin-bottom: 20px;
}
.van-button {
  width: 9rem;
  margin: auto;
  background-color: #00d48b;
  font-size: 18px;
  margin-top: 20px;
  color: #ffffff;
  border-radius: 10px;
}
</style>
